import { Link } from 'gatsby'
import { graphql } from "gatsby"
import loadable from '@loadable/component'
import React, { Component, Fragment } from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share"

const Head = loadable(() => import('../components/head'));
const Contact = loadable(() => import("../components/contact"));
const BreadCrumb = loadable(() => import('../components/breadcrumb'));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      meta
      author
      title
      category
      cover {
        title
        fluid {
          src
        }
      }
      content { json }
      createdAt(formatString: "MMMM DD, YYYY")
    }

    allContentfulBlog (sort: {fields: createdAt, order: DESC}){
      edges {
        node {
          slug
          title
          category
          createdAt(formatString: "MMMM DD, YYYY")
          thumbnail {
            fixed(quality: 100, height: 200, width: 300) {
              src
            }
          }
          content { json }
        }
      }
    }
  }
`

export default class Blog extends Component {

  state = {
    search: '',
    searchBlogs: [],
    isSearched: false,
    location: '',
    categories: ['NFT', 'Metaverse', 'GameFi', 'DeFi', 'Blockchain'],
  };

  async componentDidMount() {
    import('wowjs').then((WOW) => new WOW.WOW().init());
    let location = window.location.href;
    this.setState({ location });
  };

  handleChange = (search) => this.setState({ search },
    () => {
      if (search == '') {
        this.setState({ isSearched: false, })
        return;
      }
      this.setState({ searchBlogs: [] }, () => this.searchCategory())
    });

  searchCategory = async () => {
    let { data } = this.props;
    let { search, searchBlogs } = this.state;
    let array = [];
    let searchIndex = [];

    if (search !== '') {
      data['allContentfulBlog']['edges'].filter((content, idx) => {
        (content['node']['content']['json']['content']).filter(data => {
          (data['content']).filter(async item => {
            if (item['nodeType'] == 'text') {
              if (item['value'].toLowerCase().includes(search.toLowerCase())) await searchIndex.push(idx);
            }
          })
        })
      });

      let searchArray = await [...new Set(searchIndex)];
      await searchArray.map(item => array = [...array, data['allContentfulBlog']['edges'][item]]);

      await array.map(async (blog) => {
        if (blog['node']['title'].toLowerCase().includes(search.toLowerCase())) searchBlogs.push(blog);
      });

      await searchBlogs.map(async (data) => {
        array.map(async (blog) => {
          if (!blog['node']['title'].toLowerCase().includes(data['node']['title'].toLowerCase())) {
            searchBlogs = [...searchBlogs, blog]
          };
        })
      });
      this.setState({ searchBlogs, isSearched: true });
    }
  };

  setCategory = async (category) => localStorage.setItem('category', category);

  render() {
    let blogs = [];
    let category = '';
    let { data } = this.props;
    let { path, search, searchBlogs, isSearched, location, categories, } = this.state;
    if (data['contentfulBlog'] !== undefined) category = data['contentfulBlog']['category'];
    if (data['allContentfulBlog'] !== undefined) {
      data.allContentfulBlog.edges.forEach(item => blogs.push(item.node))
      blogs.sort(compare);
    };
    const option = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const alt = node['data']['target']['fields']['title']['en-US']
          const url = node['data']['target']['fields']['file']['en-US']['url']
          return <img alt={alt} src={url} style={{ maxWidth: '50%', marginBottom: '30px' }} />
        },
        [BLOCKS.LIST_ITEM]: (node, children) => <li style={{ listStyleType: 'initial', fontSize: '20px' }}>{children}</li>,
      }
    };


    return (
      <InnerLayout>
        <Head title={data['contentfulBlog']['title']} description={data['contentfulBlog']['meta']} thumbnail={data['contentfulBlog']['cover'] && data['contentfulBlog']['cover']['fluid']['src']} />
        <main>
          <div id="wrapper" className="softtik-page">
            <div className="blog-page single-blog-page">
              <section className="title-section-blog mb-0">
                <div className="title-section-content">
                  <div className="auto-container">
                    <div className="row">
                      <div className="col-lg-9 col-md-12">
                        {!isSearched
                          ? <div className="contact-us-title about-us-title">
                            <ul className='list-autor'>
                              <li>
                                <div className="publish-date-blog">
                                  <span>{data['contentfulBlog']['createdAt']}</span>
                                </div>
                              </li>
                              {data['contentfulBlog']['author'] &&
                                <>
                                  <li>/</li>
                                  <li>
                                    <div className='autor-name'>
                                      <p>By: <span>{data['contentfulBlog']['author']}</span></p>
                                    </div>
                                  </li>
                                </>
                              }
                            </ul>
                            <div className="sec-title">
                              <h1 className='title'> {data['contentfulBlog']['title']} </h1>
                              <BreadCrumb crumbLabel={data['contentfulBlog']['cover']['title']} />
                            </div >
                          </div>
                          : <div className="contact-us-title about-us-title">
                            <div className="sec-title">
                              <h1 className='title mb-5'> Search Results for: {search} </h1>
                            </div >
                          </div>
                        }
                      </div>
                      <div className="col-lg-9 col-md-12">
                        <div className="blog-page-slider-section position-relative bolg-detail-page-silder">
                          <div className="blog-page-slider-section-content position-relative">
                            {!isSearched
                              ? <div className="card">
                                <div className="card-img position-relative">
                                  <img
                                    className='single-blog-cover'
                                    alt={data.contentfulBlog.title}
                                    src={data.contentfulBlog.cover.fluid.src}
                                  />
                                </div>
                                <div className="card-body">
                                  <div className="social-links">
                                    <ul>
                                      <li className="share-text">Share:</li>
                                      <li><FacebookShareButton url={location} description={data.contentfulBlog.title}><a className="fb" rel="nofollow noopener"><i class="fa fa-facebook icon"></i></a></FacebookShareButton></li>
                                      <li><TwitterShareButton url={location} title={data.contentfulBlog.title}><a className="twitter" rel="nofollow noopener"><i class="fa fa-twitter icon"></i></a></TwitterShareButton></li>
                                      <li><LinkedinShareButton url={location}><a className="linkedin" rel="nofollow noopener"><i class="fa fa-linkedin icon"></i></a></LinkedinShareButton></li>
                                    </ul>
                                  </div>
                                  <div className='descp'>
                                    <div className='table-of-content'>
                                      <div id="accordion">
                                        <div class="card">
                                          <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                              <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                Table of Contents <i className='icon fa fa-align-justify'></i>
                                              </button>
                                            </h5>
                                          </div>
                                          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                              {(data['contentfulBlog']['content']['json']['content']).map(data => {
                                                return (
                                                  <ol>
                                                    {data['nodeType'].includes('heading-2') &&
                                                      <li className="main-headings">{data['content'][0]['value']}</li>
                                                    }
                                                    {data['nodeType'].includes('heading-3') &&
                                                      <li className="sub-headings"><ol>{data['content'][0]['value']}</ol></li>
                                                    }
                                                  </ol>
                                                )
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Blog Content */}
                                    {documentToReactComponents(data['contentfulBlog']['content']['json'], option)}

                                  </div>

                                  <div className="social-links">
                                    <ul>
                                      <li className="share-text">Share:</li>
                                      <li><FacebookShareButton url={location} description={data.contentfulBlog.title}><a className="fb" rel="nofollow noopener"><i class="fa fa-facebook icon"></i></a></FacebookShareButton></li>
                                      <li><TwitterShareButton url={location} title={data.contentfulBlog.title}><a className="twitter" rel="nofollow noopener"><i class="fa fa-twitter icon"></i></a></TwitterShareButton></li>
                                      <li><LinkedinShareButton url={location}><a className="linkedin" rel="nofollow noopener"><i class="fa fa-linkedin icon"></i></a></LinkedinShareButton></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              : <>
                                <div className='row'>
                                  {searchBlogs.length > 0
                                    ? <Fragment>
                                      {searchBlogs.map(blog => {
                                        return (
                                          <div className='col-lg-6 col-md-12'>
                                            <div className="search-card">
                                              <div className="search-blog-image">
                                                <Link to={`/blogs/${blog['node']['slug']}/`}>
                                                  <img
                                                    className='search-blog-cover'
                                                    alt={blog['node']['title']}
                                                    src={blog['node']['thumbnail']['fixed']['src']}
                                                  />
                                                </Link>
                                              </div>
                                              <div className='text-box'>
                                                <p>{blog['node']['createdAt']}</p>
                                                <Link to={`/blogs/${blog['node']['slug']}/`}>
                                                  <p className='title'>{blog['node']['title']}</p>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                      }
                                    </Fragment>
                                    : <div className="search-card">
                                      <div className="row search-blog-title">
                                        <p>Sorry, but nothing matched your search terms. Please try again with some different keywords.</p>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-12">
                        <div className="sidebar-blog">
                          <div className="sidebar-widget search-sidget">
                            <div className="form-group">
                              <form onSubmit={this.searchCategory}>
                                <input
                                  id="search"
                                  type="text"
                                  value={search}
                                  placeholder="Search"
                                  onChange={(e) => this.handleChange(e.target.value)}

                                />
                                <button onSubmit={this.searchCategory} type="button" className="search-btn"> <i className='icon fa fa-search'></i></button>
                              </form>
                            </div>
                          </div>

                          <div className="sidebar-widget categories-sidget">
                            <p className="title-sidebar">Categories</p>
                            <ul className="category-banner-list">
                              {categories.map(category => {
                                return (
                                  <li>
                                    <a href='/blogs' onClick={() => this.setCategory(category)} className='category-link' rel="nofollow noopener"><span className="icon" ><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/blog-logo-icon.webp' alt='softtiktech Image' /></span>{category}</a>
                                  </li>
                                )
                              })
                              }
                            </ul>
                          </div>

                          <div className="sidebar-widget blog-sidget">
                            <p className="title-sidebar">Recent Posts</p>
                            {blogs.map(blog => {
                              return category.map(category => {
                                return blog['category'].map(blogsCategory => {
                                  if (category == blogsCategory) {
                                    return (
                                      <Link to={`/blogs/${blog['slug']}/`}>
                                        <div className="blog-recent">
                                          {blog['thumbnail'] &&
                                            <img
                                              alt={blog['title']}
                                              src={blog['thumbnail']['fixed']['src']}
                                            />
                                          }
                                          <p>{blog['title']}</p>
                                        </div>
                                      </Link>
                                    )
                                  }
                                })
                              })
                            })
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className='get-intouch-sec blog-contact-area' id="get-intouch">
                <Contact />
              </section>
            </div>
          </div>
        </main>
      </InnerLayout>
    )
  }
}

function compare(a, b) {
  if (a.date > b.date)
    return -1;
  if (a.date < b.date)
    return 1;
  return 0;
}